import { borderRadiusMedium, colorBlueLightest } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent, ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const TableHead: FunctionComponent<Props> = ({ children }) => {
  return (
    <thead
      css={css`
        > tr > * {
          background-color: ${colorBlueLightest};
          height: 32px;
          padding: 0 16px;
        }

        > tr > :first-of-type {
          border-top-left-radius: ${borderRadiusMedium};
        }

        > tr > :last-of-type {
          border-top-right-radius: ${borderRadiusMedium};
        }
      `}
    >
      {children}
    </thead>
  );
};

export default TableHead;
