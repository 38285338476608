import type { FunctionComponent, ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const TableRow: FunctionComponent<Props> = ({ children }) => {
  return <tr>{children}</tr>;
};

export default TableRow;
