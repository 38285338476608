import { css } from "@emotion/react";
import type { FunctionComponent, ReactNode } from "react";

import Text from "../Text";

interface Props {
  align?: "left" | "center" | "right" | "justify";
  children: ReactNode;
  padding?: string;
  size?: "small" | "medium" | "large";
  verticalAlign?: "baseline" | "top" | "middle" | "bottom";
}

const TableHeaderCell: FunctionComponent<Props> = ({
  align = "left",
  children,
  padding = "1rem",
  verticalAlign = "bottom",
  size = "small",
}) => {
  return (
    <Text
      as={"th"}
      css={css`
        padding: ${padding};
        text-align: ${align};
        vertical-align: ${verticalAlign};
      `}
      size={size}
      style={{ padding: padding }}
      weight={"medium"}
    >
      {children}
    </Text>
  );
};

export default TableHeaderCell;
