import {
  colorSteelMedium,
  fontFamilyBase,
  fontSizeXsmall,
  sizeXlarge,
  sizeXsmall,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Image from "../Image";

interface Props {
  alt?: string;
  src: string;
  title?: string;
}

const Figure: FunctionComponent<Props> = ({ alt, src, title }) => {
  return (
    <figure
      css={css`
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: ${sizeXsmall};
        margin-bottom: ${sizeXlarge};
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        max-width: 100%;
        padding: 0;
      `}
    >
      <Image alt={alt} src={src} title={title} />

      {alt ? (
        <figcaption
          css={css`
            color: ${colorSteelMedium};
            font-family: ${fontFamilyBase};
            font-size: ${fontSizeXsmall};
            max-width: 80%;
            text-align: center;
          `}
        >
          {alt}
        </figcaption>
      ) : null}
    </figure>
  );
};

export default Figure;
