import {
  borderRadiusMedium,
  borderStandard,
  colorBlueLightest,
  colorSteelDarker,
  colorSteelDarkest,
  fontFamilyMonospace,
  fontSizeXxsmall,
  fontWeightMedium,
  sizeLarge,
  sizeMedium,
  sizeXxsmall,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { CSSProperties } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneLight } from "react-syntax-highlighter/dist/cjs/styles/prism";

import { replaceLast } from "../PreformattedText/utils";

interface Props {
  children?: string;
  className?: string;
  highlightedLines?: number[];
  showLineNumbers?: boolean;
  variant?: "default" | "no-background";
}

/**
 * An inline element for displaying code as highlighted, monospaced type.
 *
 * To display a block of code, use the `PreformattedText` component.
 */
const Code = ({
  children = "",
  className,
  showLineNumbers,
  highlightedLines = [],
  variant = "default",
}: Props) => {
  const match = /language-(\w+)/.exec(className || "");

  const codeCss =
    variant === "no-background"
      ? css`
          background: transparent;
          border: 0;
          color: ${colorSteelDarker};
        `
      : css`
          background: ${colorBlueLightest};
          border: ${borderStandard};
          border-radius: ${borderRadiusMedium};
          color: ${colorSteelDarkest};
        `;

  return (
    <>
      {match ? (
        <SyntaxHighlighter
          PreTag={"div"}
          codeTagProps={{
            style: {
              fontSize: "inherit",
              lineHeight: "inherit",
            },
          }}
          customStyle={{
            backgroundColor:
              variant === "no-background" ? "transparent" : colorBlueLightest,
            fontSize: fontSizeXxsmall,
            lineHeight: "1.25",
            padding: `${sizeMedium} ${sizeLarge}`,
          }}
          language={match[1]}
          lineNumberStyle={{
            minWidth: "1rem",
          }}
          lineProps={(lineNumber) => {
            const style: CSSProperties = { display: "block" };
            if (highlightedLines?.includes(lineNumber)) {
              style["backgroundColor"] = "#fee9b9";
            }
            return { style };
          }}
          showLineNumbers={showLineNumbers}
          style={oneLight}
          wrapLines={true}
        >
          {replaceLast(children, /\n/, "")}
        </SyntaxHighlighter>
      ) : (
        <code
          className={className}
          css={css`
            ${codeCss}
            box-sizing: border-box;
            font-family: ${fontFamilyMonospace};
            font-size: ${fontSizeXxsmall};
            font-weight: ${fontWeightMedium};
            padding: 0 ${sizeXxsmall};
            overflow: scroll;
          `}
        >
          {children}
        </code>
      )}
    </>
  );
};

export default Code;
