import { sizeLarge } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import { omit } from "lodash-es";
import type { HTMLProps } from "react";

type Props = HTMLProps<HTMLUListElement>;

const UnorderedList = (props: Props) => {
  return (
    <ul
      // MDX passes some weird element-invalid props,
      // so just exclude them here
      {...omit(props, "ordered")}
      css={css`
        margin-bottom: ${sizeLarge};
        margin-top: 0;
      `}
    />
  );
};

export default UnorderedList;
