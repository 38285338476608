import classNames from "classnames";
import type { FunctionComponent } from "react";
import { useEffect } from "react";
import { createPortal } from "react-dom";

interface Props {
  /** A Wistia video ID. */
  id: string;

  /** A handler to call when the video closes. */
  onClose: () => void;
}

type WistiaVideoBindCallback = () => void;

interface WistiaVideo {
  bind: (eventName: string, callback: WistiaVideoBindCallback) => void;
  play: () => void;
  unbind: unknown;
}

/**
 * Plays a Wistia video in a modal.
 *
 * The component loads the Wistia player JS on instantiation by
 * appending a script element to the document head-- but only if
 * Wistia is not already present on the global scope. Some pages
 * may choose to explicitly include the Wistia script in the page
 * head in order to speed up the appearance of videos when the user
 * requests them. If we wait until the user request and then
 * load Wistia, there might be a lag of as much as a few seconds
 * between a click and the start of video playback.
 *
 * This component also appends a div to the document body for the
 * Wistia player to target. Both the player div and other dynamically
 * generated Wistia elements are destroyed when this component gets
 * removed from the React tree.
 */
const WistiaPopover: FunctionComponent<Props> = ({ id, onClose }) => {
  useEffect(() => {
    // Add globals for Wistia config
    window._wq = window["_wq"] || [];
    window["_wq"].push({
      id,
      onReady: (video: WistiaVideo) => {
        video.bind("popoverhide", function () {
          onClose();
          return video.unbind;
        });
        video.play();
      },
    });

    // Load player JS (if not already present)
    if (!window.Wistia) {
      const script = document.createElement("script");
      script.async = true;
      script.src = `${window.location.protocol}//fast.wistia.com/assets/external/E-v1.js`;
      document.head.appendChild(script);
    }
  }, [id, onClose]);

  return createPortal(
    <div
      className={classNames(
        "WistiaPopover",
        "wistia_embed",
        `wistia_async_${id}`,
        "popover=true",
        "videoFoam=true",
      )}
    />,
    document.body,
  );
};

export default WistiaPopover;
