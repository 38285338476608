import { css } from "@emotion/react";
import type { FunctionComponent, ReactNode } from "react";

import Text from "../Text";

interface Props {
  align?: "left" | "center" | "right" | "justify";
  children?: ReactNode;
  padding?: string;
  size?: "small" | "medium" | "large";
  verticalAlign?: "baseline" | "top" | "middle" | "bottom";
}

const TableDataCell: FunctionComponent<Props> = ({
  align = "left",
  children,
  padding = "1rem",
  verticalAlign = "top",
  size = "small",
}) => {
  return (
    <Text
      as={"td"}
      color={"midgray"}
      css={css`
        text-align: ${align};
        vertical-align: ${verticalAlign};
      `}
      size={size}
      style={{
        padding: padding,
      }}
    >
      {children}
    </Text>
  );
};

export default TableDataCell;
