import {
  borderStandard,
  colorWhite,
  sizeLarge,
  sizeMedium,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { ReactNode } from "react";
import { Children, isValidElement, useState } from "react";

import Button from "../Button";
import Icon from "../Icon";
import Text from "../Text";
import { cloneElement, codeCss, highlightCss, preCss } from "./utils";

interface Props {
  children: JSX.Element | string;
  className?: string;
  highlight?: boolean;
  highlightedLines?: number[];
  showLineNumbers?: boolean;
}

/**
 * A block-level element for displaying highlighted, monospaced type.
 *
 * Good for code blocks. To display code inline, use the `Code` component.
 */
const PreformattedText = ({ children, ...props }: Props) => {
  const [copied, setCopied] = useState<boolean>(false);

  // While it would be logical to have this be a pre element instead of a div,
  // if you use pre there's an mdx bug that renders two nested pre tags.
  const childrenWithCss = Children.map(children, (child) => {
    if (!isValidElement<{ children?: ReactNode; className: string }>(child)) {
      return (
        <div
          css={css`
            overflow: scroll;
            padding: ${sizeMedium} ${sizeLarge};
          `}
        >
          {child}
        </div>
      );
    }

    return cloneElement(child, {
      css: [
        codeCss,
        css`
          border: none;
          padding: ${sizeMedium} ${sizeLarge};
          font-size: 0.875rem;
        `,
      ],
      highlightedLines: props.highlightedLines,
      showLineNumbers: props.showLineNumbers,
    });
  });

  return (
    <pre
      {...props}
      css={[
        css`
          margin: 0 0 1rem 0;
          position: relative;
        `,
        props.highlight ? highlightCss : preCss,
      ]}
    >
      <div
        css={css`
          border-radius: 6px 6px 0 0;
          border-bottom: ${borderStandard};
          background: ${colorWhite};
          display: flex;
          justify-content: flex-end;
          padding: 0.5rem 1.5rem;
        `}
      >
        <Button
          background={"transparent"}
          color={copied ? "gray" : "blue"}
          css={css`
            align-items: center;
            gap: 6px;
            height: min-content;
            padding: 0;
            width: min-content;
          `}
          onClick={() => {
            if (!navigator.clipboard) {
              return;
            }

            const text =
              typeof children === "string"
                ? children
                : children.props?.children;

            if (text) {
              navigator.clipboard.writeText(text);
              setCopied(true);
            }
          }}
          onMouseLeave={() => {
            if (copied) {
              setCopied(false);
            }
          }}
        >
          <Icon color={"inherit"} size={"14px"} source={"clipboard"} />
          <Text as={"span"} color={"inherit"} size={"small"} weight={"medium"}>
            {copied ? "Copied!" : "Copy"}
          </Text>
        </Button>
      </div>
      {childrenWithCss}
    </pre>
  );
};

export default PreformattedText;
