import { borderRadiusMedium, colorWhite } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent, ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const TableBody: FunctionComponent<Props> = ({ children }) => {
  return (
    <tbody
      css={css`
        > tr > * {
          background-color: ${colorWhite};
          height: 32px;
          padding: 0 16px;
        }

        > :last-of-type > :first-of-type {
          border-bottom-left-radius: ${borderRadiusMedium};
        }

        > :last-of-type > :last-of-type {
          border-bottom-right-radius: ${borderRadiusMedium};
        }
      `}
    >
      {children}
    </tbody>
  );
};

export default TableBody;
