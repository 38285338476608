import {
  colorSteelDarker,
  colorSteelLighter,
  sizeLarge,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent, ReactNode } from "react";

import Text from "../Text";

interface Props {
  children: ReactNode;
}

/**
 * An indented text block containing a quotation.
 */
const Blockquote: FunctionComponent<Props> = ({ children }) => {
  return (
    <Text
      as={"blockquote"}
      css={css`
        border-left: 8px solid ${colorSteelLighter};
        box-sizing: border-box;
        color: ${colorSteelDarker};
        margin-bottom: ${sizeLarge};
        padding-left: 1rem;
      `}
      size={"small"}
    >
      {children}
    </Text>
  );
};

export default Blockquote;
