import { useEffect, useState } from "react";

const useMobileDetect = () => {
  const [isMobile, setIsMobile] = useState(false);
  const hasWindow = typeof window !== "undefined";

  useEffect(() => {
    if (hasWindow) {
      setIsMobile(window.navigator.maxTouchPoints > 1);
    }
  }, [hasWindow]);

  return isMobile;
};

export default useMobileDetect;
