import {
  borderRadiusMedium,
  borderStandard,
  colorBlueDark,
  colorBlueLight,
  colorBlueMedium,
  colorSteelLighter,
  colorWhite,
  fontFamilyBase,
  mediaTabletLandscape,
  sizeSmall,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";

import type { BackgroundMode, ButtonSize, MobileMode } from "./types";

export const getBaseButtonCss = (height: string, width: string) => {
  return css`
    align-items: center;
    background: none;
    border: none;
    border-radius: ${borderRadiusMedium};
    box-sizing: border-box;
    color: white;
    cursor: pointer;
    display: flex;
    font-family: ${fontFamilyBase};
    gap: ${sizeSmall};
    height: ${height};
    justify-content: center;
    max-width: 24rem;
    overflow: hidden;
    text-decoration: none;
    white-space: nowrap;
    width: ${width};
  `;
};

const disabledPrimary = css`
  color: ${colorBlueLight};
  cursor: default;
  pointer-events: none;
`;

export const getPrimaryButtonCss = (
  mode: BackgroundMode,
  size: ButtonSize,
  mobile: MobileMode | undefined,
) => {
  return css`
    ${getBaseButtonCss(size.height, size.width)}
    background: ${mode === "dark" ? colorWhite : colorBlueMedium};
    color: ${mode === "dark" ? colorBlueMedium : colorWhite};
    border: solid 1px ${mode === "dark" ? colorSteelLighter : colorBlueMedium};
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.06);
    &:hover {
      background: ${mode === "dark" ? colorBlueLight : colorBlueDark};
      border: solid 1px ${mode === "dark" ? colorBlueLight : "none"};
      color: ${mode === "dark" ? colorBlueMedium : colorWhite};
    }
    @media (max-width: ${mediaTabletLandscape}) {
      ${mobile?.disabled ? disabledPrimary : null}
    }
  `;
};

export const getSecondaryButtonCss = (
  mode: BackgroundMode,
  size: ButtonSize,
) => {
  return css`
    ${getBaseButtonCss(size.height, size.width)}
    background: none;
    border: ${borderStandard};
    color: ${mode === "dark" ? colorWhite : colorBlueMedium};
    &:hover {
      background: ${mode === "dark" ? "rgba(255, 255, 255, .15)" : "#E1EFFC"};
      border: solid 1px ${mode === "dark" ? colorBlueLight : "none"};
      color: ${mode === "dark" ? colorWhite : colorBlueMedium};
    }
  `;
};
