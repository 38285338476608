import type { TextColor, TextWeight } from "@10xdev/types";
import { sizeLarge } from "@10xdev/design-tokens";
import type { CSSProperties, FunctionComponent, ReactNode } from "react";

import Text from "../Text";

interface Props {
  children: ReactNode;
  className?: string;

  /** An enumerated color. */
  color?: TextColor;

  /**
   * Specifies whether the component should behave differently
   * on smaller screens.
   *
   * The media-aware behavior rules are straightforward:
   * if responsive is set to `true` then we inspect the
   * passed size and use the next size down. So for example,
   * if the passed size is `xlarge`, then we use `large`
   * instead on smaller screens. "Smaller" means screens
   * less than 900px wide, vaguely a tablet in horizontal
   * orientation.
   *
   * This behavior is opt-in. By default, the component renders
   * at the passed size regardless of media context.
   * */
  responsive?: boolean;

  /** Text size */
  size?: "small" | "medium" | "large";

  /** A React style object to be applied inline. Use sparingly. */
  style?: CSSProperties;

  /** An enumerated font weight. */
  weight?: TextWeight;
}

/**
 * A typography primitive for paragraphs. This builds on top
 * of the `Text` component to restrict the underlying element
 * to `p` and the size to `medium`. It also provides a consistent
 * lower margin, assumes `regular` weight, and makes paragraphs
 * easier to scan for in JSX.
 */
const Paragraph: FunctionComponent<Props> = ({
  children,
  className,
  color,
  responsive = false,
  style,
  weight,
  size = "small",
}) => {
  return (
    <Text
      as={"p"}
      className={className}
      color={color}
      css={{
        marginBottom: sizeLarge,
        ...style,
      }}
      responsive={responsive}
      size={size}
      weight={weight || "regular"}
    >
      {children}
    </Text>
  );
};

export default Paragraph;
