import { borderRadiusMedium, colorSteelLighter } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent, ReactNode } from "react";

import TableBody from "./TableBody";
import TableDataCell from "./TableDataCell";
import TableHead from "./TableHead";
import TableHeaderCell from "./TableHeaderCell";
import TableRow from "./TableRow";
import type { TabularData } from "./types";

interface Props {
  children?: ReactNode;
  /**
   * An optional tabular data object. If no data is passed,
   * the component is expecting `children` instead.
   * */
  data?: TabularData;
  layout?: "fixed" | "auto";
  marginBottom?: string;
}

/**
 * Displays tabular data as a two-dimensional table:
 * rows and columns of cells that contain data items.
 *
 * There are three distinct ways to use this component:
 *
 * 1. **Basic:** pass a tabular data object and the component
 *    automatically spits out a table. The Typescript structure
 *    of this object is `TabularData<TabularDataItem>`. See the
 *    "Basic" story for an example.
 *
 * 2. **Advanced:** if your use case is too complex for the Basic
 *    approach, you can assemble a custom table using the `TableBody`,
 *    `TableDataCell`, `TableHead`, `TableHeaderCell`, and `TableRow`
 *    components. See the "Advanced" story for an example.
 *
 * 3. **Markdown:** content authors working in Charlie or directly
 *    on Markdown files in the repo can construct tables in Markdown,
 *    and the MDX compiler will translate them to React. Unless you're
 *    a content author or you're working on MDX compilation, it's
 *    unlikely that you'll have much contact with this third approach.
 *
 * The component expects either data (approach #1) or children
 * (approaches #2 and #3). If neither data nor children are passed,
 * the component declines to render.
 */
const Table: FunctionComponent<Props> = ({
  children,
  data,
  layout = "auto",
  marginBottom = "1.5rem",
}) => {
  if (!children && !data) {
    console.warn("Table has neither children nor data to render.");
    return null;
  }

  let rows: number[] = [];
  if (data) {
    const numRows = Math.max(...data.map((item) => item.entries.length));
    rows = Array.from({ length: numRows }).map((item, index) => index);
  }

  return (
    <div
      css={css`
        overflow-x: auto;
      `}
    >
      <table
        css={css`
          background-color: ${colorSteelLighter};
          border-spacing: 1px;
          border-radius: ${borderRadiusMedium};
          margin-bottom: ${marginBottom};
          table-layout: ${layout};
          width: 100%;
        `}
      >
        {children || (
          <>
            <TableHead>
              <TableRow>
                {data!.map((item) => {
                  const { title } = item;
                  return <TableHeaderCell key={title}>{title}</TableHeaderCell>;
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row}>
                  {data!.map((item, index) => {
                    const { entries } = item;
                    const content = entries[row];
                    return (
                      <TableDataCell key={`row${row}-column${index}`}>
                        {content}
                      </TableDataCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </>
        )}
      </table>
    </div>
  );
};

export default Table;
